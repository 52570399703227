export class UrlHelper {

    #url

    constructor(url) {
        this.#url = new URL(url)
    }

    get path() {
        return this.#url.pathname
    }

    get query() {
        return Object.fromEntries(this.#url.searchParams)
    }

}
