import { AudioPlayer } from './AudioPlayer'

export class AudioManager {

    constructor() {
        this.player = new AudioPlayer('audio')
    }

    /**
     * Play the web-form step's audio
     * @param {?string} url
     */
    async autoplayStepAudio(url) {
        await this.player.autoplay(url)
    }

    /**
     * Stop playing any previous completion audio (e.g. silence track), and play the completed web-form audio.
     *
     * Note: This is called from the livewire layer.
     */
    async playCompletedWebFormAudio(url, loop = false) {
        return this.player.stop().then(() => this.player.autoplay(url))
    }

    /**
     * To facilitate the async playing of completion audio we need to prime the audio
     * element with a silent audio file. This will allow the audio to play immediately without user interaction
     * when they hit the thank-you page...
     */
    primeCompletionAudio(url) {
        return this.playCompletedWebFormAudio(url, true)
    }

}
