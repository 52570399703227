import { UrlHelper } from './urlHelper'
import { RouteParser } from './routeParser'

export default function(Alpine) {

    const routeParser = new RouteParser()
    const afterEachHooks = []

    const state = Alpine.reactive({
        href: location.href,
        path: '',
        query: {},
        params: {},
    })

    const route = {

        get path() {
            return state.path
        },

        get query() {
            return state.query
        },

        get params() {
            return state.params || {}
        },

        get routes() {
            return routeParser.getRoutes()
        },

        push(..._arguments) {
            return push(..._arguments)
        },

        replace(..._arguments) {
            return replace(..._arguments)
        },

        is(...paths) {
            return routeParser.is(getTargetURL(state.href).path, ...paths)
        },

        not(...paths) {
            return routeParser.not(getTargetURL(state.href).path, ...paths)
        },

        addRoutes(paths) {

            for (const path of paths) {
                routeParser.add(path)
            }

            parseRoute()

        },

        afterEach(callback) {
            afterEachHooks.push(callback)
        },

    }

    Alpine.$router = route
    Alpine.magic('router', () => route)

    window.addEventListener('popstate', () => {

        state.href = location.href
        parseRoute()

    })

    function getTargetURL(href) {
        return new UrlHelper(href)
    }

    function parseRoute() {

        const url = getTargetURL(state.href)

        state.path = url.path
        state.query = url.query
        state.params = routeParser.match(url.path)

    }

    function push(path, replace = false) {

        if (!path.startsWith(location.origin)) {
            path = location.origin + path
        }

        if (location.href !== path) {

            history[ replace ? 'replaceState' : 'pushState' ]({}, '', path)
            state.href = path
            handleAfterEachHooks()
            parseRoute()

        }

    }

    function replace(path) {
        push(path, true)
    }

    function handleAfterEachHooks() {

        Alpine.nextTick(() => {

            for (const callback of afterEachHooks) {
                callback()
            }

        })

    }

}
