import { Matcher } from './matcher'

export class RouteParser {

    #patterns = {}

    add(route) {

        this.#patterns = {
            ...this.#patterns,
            [ route ]: Matcher.prepareRegex(route),
        }

    }

    getRoutes() {
        return Object.keys(this.#patterns)
    }

    match(targetPath) {

        for (const route in this.#patterns) {

            const found = Matcher.match(targetPath, this.#patterns[ route ])

            if (found) {
                return found === true ? {} : found
            }

        }

        return false

    }

    is(targetPath, ...routes) {

        for (const route of routes) {

            if (!Object.hasOwn(this.#patterns, route)) {
                this.#patterns[ route ] = Matcher.build(route)
            }

            if (Matcher.is(targetPath, this.#patterns[ route ])) {
                return true
            }

        }

        return false

    }

    not(targetPath, ...routes) {
        return !this.is(targetPath, ...routes)
    }

}
