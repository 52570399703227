export class Matcher {

    static prepareRegex(path) {

        if (path.indexOf(':') === -1) {
            return path
        }

        const pattern = path.split('/').map(segment => {

            if (!segment.startsWith(':')) {
                return segment
            }

            let field = segment.substr(1)
            let fieldPattern = '[^/]+'

            const matches = field.match(/\((.+?)\)/)

            if (matches) {

                field = field.substr(0, field.indexOf('('))
                fieldPattern = matches[ 1 ]

            }

            return `(?<${ field }>${ fieldPattern })`

        }).join('/')

        return new RegExp(`^${ pattern }$`)

    }

    static match(path, pattern) {

        if (pattern instanceof RegExp) {

            const found = path.match(pattern)

            if (!found) {
                return false
            }

            return { ...found.groups }

        }

        return path === pattern

    }

    static is(path, pattern) {

        if (pattern instanceof RegExp) {
            return pattern.test(path)
        }

        return path === pattern

    }

}
