export class QuinnStreet {

    constructor() {

        this.tokens = {}
        this.script = null

    }

    initialize(tokens) {

        this.tokens = tokens

        if (!this.script) {

            const script = document.createElement('script')

            script.async = true
            script.defer = true
            script.src = '//www.nextinsure.com/listingdisplay/loader/sh'

            script.onload = () => {

                this.script = window.sh
                this.initializeWidget()

            }

            document.body.appendChild(script)

        } else {
            this.initializeWidget()
        }

    }

    initializeWidget() {
        this.script.initialize(this.tokens, 'qsWidgetContainer')
    }

}
